import { GeneralAny } from 'types';

import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from '../constants/env';

// by declaring this, we can avoid typescript throwing an error for window.gtag
declare global {
  interface Window {
    gtag: GeneralAny;
  }
}

const CAN_TRACK = !!GOOGLE_ANALYTICS_MEASUREMENT_ID && window.gtag;

export const initialize = () => {
  if (CAN_TRACK) {
    // nothing to init, but we have disabled intial page view
    // in the code setup code for google analytics ( public/index )
  }
};

export const trackIdentity = ({ userId }: { userId: string }) => {
  if (CAN_TRACK) {
    // we set the user_id for ga
    window.gtag('set', 'user_id', userId);

    // Note: we do't send any additional properties
    // because ga doesn't want user identifiable info
    // and only really wants information that segments users
  }
};

export const trackEvent = ({
  name,
  properties = {}
}: {
  name: string;
  properties?: GeneralAny;
}) => {
  if (CAN_TRACK) {
    const safeProperties: {
      [key: string]: number | string;
    } = {};

    // Note: properties need to be sent as key:value pairs
    // so, for any non string/number, we will attempt to stringify
    for (const [key, value] of Object.entries(properties)) {
      if (value !== null && value !== undefined) {
        safeProperties[key] =
          typeof value !== 'object'
            ? (value as string | number)
            : (JSON.stringify(value) as string);
      }
    }

    window.gtag('event', name, safeProperties);
  }
};

export const trackPage = ({
  name,
  properties = {}
}: {
  name: string;
  properties?: GeneralAny;
}) => {
  if (CAN_TRACK) {
    trackEvent({
      name: 'page_view',
      properties: {
        page_title: name,
        ...properties
      }
    });
  }
};
