import {
  initialize as logrocketInitialize,
  trackIdentity as logrocketTrackIdentity,
  trackException as logrocketTrackException,
  trackError as logrocketTrackError,
  trackEvent as logrocketTrackEvent,
  trackPage as logrocketTrackPage
} from './logrocket';
import {
  initialize as gaInitialize,
  trackIdentity as gaTrackIdentity,
  trackEvent as gaTrackEvent,
  trackPage as gaTrackPage
} from './ga';

// some tools we use
const STORYBOOK_RUNNING = process.env.STORYBOOK;
const JEST_RUNNING =
  process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test';

// don't enable analytics for internal tools
const ANALYTICS_ENABLED = !STORYBOOK_RUNNING && !JEST_RUNNING;

/**
 * initialize analytics tools if needed
 */
export const initializeAnalytics = () => {
  if (ANALYTICS_ENABLED) {
    logrocketInitialize();
    gaInitialize();
  }
};

/**
 * captures a user identity for analytics
 * @param {object} userObj - the user model object
 * @param {object} [additionalProperties] - additional properties to capture
 */
export const captureUserIdentityForAnalytics = (
  userObj,
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    if (userObj?.username) {
      const userId = `sso_${userObj.username}`;
      const properties = {
        firstName: userObj.first_name,
        lastName: userObj.last_name,
        name: userObj.fullName,
        email: userObj.email,
        groups: userObj.groups,
        ...additionalProperties
      };

      logrocketTrackIdentity({
        userId: userId,
        properties: properties
      });
      gaTrackIdentity({ userId: userId });
    }
  }
};

/**
 * captures a page view for analytics
 * @param {string} pageName - the name of the page you want to track
 * @param {object} [additionalProperties] - additional properties you want to capture
 */
export const capturePageViewForAnalytics = (
  pageName = '',
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    if (pageName) {
      logrocketTrackPage({
        name: pageName,
        properties: additionalProperties
      });
      gaTrackPage({
        name: pageName,
        properties: additionalProperties
      });
    }
  }
};

/**
 * captures an event for analytics
 * @param {string} eventName - the name of the event to capture
 * @param {object} [additionalProperties] - additional properties you want to capture
 */
export const captureEventForAnalytics = (
  eventName = '',
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    if (eventName) {
      logrocketTrackEvent({
        name: eventName,
        properties: additionalProperties
      });
      gaTrackEvent({
        name: eventName,
        properties: additionalProperties
      });
    }
  }
};

/**
 * captures an exception
 * @param {Error} error - The exception to capture
 * @param {object} [additionalProperties] - additional properties you want to capture
 */
export const captureExceptionForAnalytics = (
  error,
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    logrocketTrackException({ error: error, properties: additionalProperties });
  }
};

/**
 * captures an error
 * @param {string} error - The error to capture
 * @param {object} [additionalProperties] - additional properties you want to capture
 */
export const captureErrorForAnalytics = (error, additionalProperties = {}) => {
  if (ANALYTICS_ENABLED) {
    logrocketTrackError({ error: error, properties: additionalProperties });
  }
};
