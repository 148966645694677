import { GenericObjectValues } from 'types/index';

export const FEATURE_FLAGS_QUERY = 'ffs';

export const FEATURE_NAME = {
  MSRE_SHOW_BUYING_INTENT_FOR_LEAD: 'msreshowbuyingintent',
  SHOW_CUSTOMERS_APPLICATIONS: 'showcustomersapplications'
} as const;

export type FEATURE_NAME = GenericObjectValues<typeof FEATURE_NAME>;

export const FEATURE_FLAGS: Record<FEATURE_NAME, boolean> = {
  [FEATURE_NAME.MSRE_SHOW_BUYING_INTENT_FOR_LEAD]: false,
  [FEATURE_NAME.SHOW_CUSTOMERS_APPLICATIONS]: false
};
